import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Erreur 404: Page non trouvée</h1>
    <p className="mt-2">La page que vous recherchez n'existe pas. Essayez dans quelques mois. On ne sait jamais.</p>
  </Layout>
)

export default NotFoundPage
